import React from "react";

export const Contact = (props) => {
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-3 col-md-offset-0 contact-info">
            <div className="contact-item">
              <h3>Organizing institution</h3>
              <img src="img/HADsmall.png" width="100px" alt="" />
              <br></br>
              <br></br>
              <p>HRVATSKO AKUSTIČKO DRUŠTVO</p>
              <p>Croatian Acoustic Society</p>
              <p>Unska 3, 10000 Zagreb</p>
              <p>Croatia</p>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-0 contact-info">
            <div className="contact-item">
              <h3>Scientific Committee</h3>
              <p>Andrea Andrijašević, Croatia</p>
              <p>Tino Bucak, Croatia</p>
              <p>Karlo Filipan, Croatia</p>
              <p>Marko Horvat, Croatia</p>
              <p>Jurica Ivošević, Croatia</p>
              <p>Kristian Jambrošić, Croatia</p>
              <p>Antonio Petošić, Croatia</p>
              <p>Marjan Sikora, Croatia</p>
              <p>Zoran Veršić, Croatia</p>
              <p>Fülöp Augusztinovicz, Hungary</p>
              <p>Attila Balazs Nagy, Hungary</p>
              <p>Samo Beguš, Slovenia</p>
              <p>Andrej Biček, Slovenia</p>
              <p>Luka Čurović, Slovenia</p>
              <p>Mateja Dovjak, Slovenia</p>
              <p>Beata Mesterhazy, Hungary</p>
              <p>Andras Muntag, Hungary</p>
              <p>Herbert Müllner, Austria</p>
              <p>Jernej Polajnar, Slovenia</p>
              <p>Jurij Prezelj, Slovenia</p>
              <p>Rok Prislan, Slovenia</p>
              <p>Louena Shtrepi, Italy</p>
              <p>Daniel Svenšek, Slovenia</p>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-0 contact-info">
            <div className="contact-item">
              <h3>Organizing Committee</h3>
              <p>Andrea Andrijašević, Croatia</p>
              <p>Tino Bucak, Croatia</p>
              <p>Karlo Filipan, Croatia</p>
              <p>Marko Horvat, Croatia</p>
              <p>Jurica Ivošević, Croatia</p>
              <p>Kristian Jambrošić, Croatia</p>
              <p>Antonio Petošić, Croatia</p>
              <p>Marjan Sikora, Croatia</p>
              <p>Zoran Veršić, Croatia</p>
              <p>Attila Balazs Nagy, Hungary</p>
              <p>Mateja Dovjak, Slovenia</p>
              <p>Zoltan Horvath, Hungary</p>
              <p>Beata Mesterhazy, Hungary</p>
              <p>Rok Prislan, Slovenia</p>
            </div>
          </div>

          <div className="col-md-3 col-md-offset-0 contact-info">
            <div className="contact-item">
              <h3>Congress Chair</h3>
              <p>Marko Horvat</p>
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                Unska 3, 10000 Zagreb, Croatia
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                info@alpsadriaacoustics.eu
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>&copy; 2025 HAD</p>
        </div>
      </div>
    </div>
  );
};
